.ncc-pagination {
    display: inline-flex;
    justify-content: space-around;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 10px;
    .page-item {
        // width: 12px;
        // list-style: none;
        // border: 1px solid #dee2e6;
        // padding: 8px;
        // border-radius: 10px;
        // margin-right: 2px;
        // cursor: pointer;
        // text-align: center;
         

        &.active {
            background-color: #2f88d9;
            border-color: #2f88d9;
            color: #fff;
            .page-link {
                
            }
        }
        .page-link {
            &:active,
            &:focus {
                box-shadow: none!important;
            }
            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}