@import "_spinner";

/* cyrillic-ext */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0-ExcOPIDUg-g.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0yExcOPIDUg-g.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$over-flows: 'auto', 'scroll', 'hidden';
$text-aligns: 'center', 'justify', 'left', 'right';
$displays: "inline-flex", "inline-block";
$cursor-styles: "pointer", "not-allowed";
$text-color-styles: "red", 'blue';

body {
    font-family: 'PT Sans' !important;
}

.text-right {
    text-align: right;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dbdee1;
    position: sticky;
    top: 0;
    z-index: 3;

    .header-right {
        display: flex;
        gap: 30px;
    }
    
    .log-out-active {
        // margin-left: 30px
    }
    .link-navigate {
        cursor: pointer;
        // color: var(--bs-black);
        text-decoration: none;
    }
}

.no-results-wrapper {
    p {
        color: #ff0000;
        font-weight: bold;
    }
}

.dfl {
    display: flex;

    &.top-navigation {
        align-items: center;
        align-content: center;

        svg {
            margin-right: 10px;
        }
    }

    &-r-jstrt {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
    }

    &-r-spbtw {
        display: flex;
        justify-content: space-between;
    }

    &-r-wrap {
        display: flex;
        flex-flow: row wrap;
    }

    &-r-jctr {
        display: flex;
        flex-flow: row;
        justify-content: center;
    }

    &-r-jend {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
    }

    &-r-jend-alctr {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
    }

    &-r-jctr-alctr {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
    }

    &-r-sp-ar {
        display: flex;
        justify-content: space-around;
    }

    &-r-alctr {
        display: flex;
        align-items: center;
    }

    &-c-jstrt {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
    }

    &-c-jctr {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &-c-jctr-alctr {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }

    &-c-rv-nowrap-jend {
        display: flex;
        flex-flow: column-reverse;
        justify-content: flex-end;
    }

    &-c-jend-alctr {
        display: flex;
        justify-content: flex-end;
        flex-flow: column;
    }

    &-c-alctr {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    &-c-alctr-jend {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-end;
    }

    &-c-alstrt-jctr {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
    }

    &-c-sp-ar {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
    }

    &-c-sp-btw {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    &-r-spbtw-alctr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}

@each $dp in $displays {
    .dp-#{$dp} {
        display: #{$dp};
    }
}

@for $i from 400 through 800 {
    .ncc-fw-#{$i} {
        font-weight: #{$i};
    }
}

@for $i from 200 through 600 {
    .ncc-w-px-#{$i} {
        width: #{$i}px !important;
    }
}

@for $i from 0 through 200 {
    .ncc-d-grid-#{$i} {
        display: grid;
        grid-template-columns: repeat(autofill, minmax(#{$i}#{'%'}, 1fr));
    }

    .ncc-w-px-#{$i} {
        width: #{$i}px !important;
    }

    .opacity-#{$i} {
        opacity: #{$i};
    }

    .ncc-p-#{$i} {
        padding: #{$i}#{'%'};
    }

    .ncc-fs-#{$i} {
        font-size: #{$i}px;
    }


.ncc-pt-#{$i} {
    padding-top: #{$i}#{'%'};
}

.ncc-pr-#{$i} {
    padding-right: #{$i}#{'%'} !important;
}

.ncc-pb-#{$i} {
    padding-bottom: #{$i}#{'%'};
}

.ncc-pl-#{$i} {
    padding-left: #{$i}#{'%'};
}

.ncc-p-px-#{$i} {
    padding: #{$i}px;
}

.ncc-pt-px-#{$i} {
    padding-top: #{$i}px;
}

.ncc-pr-px-#{$i} {
    padding-right: #{$i}px;
}

.ncc-pb-px-#{$i} {
    padding-bottom: #{$i}px;
}

.ncc-pl-px-#{$i} {
    padding-left: #{$i}px;
}

.ncc-h-#{$i} {
    height: #{$i}#{'%'};
}

.ncc-h-vh-#{$i} {
    height: #{$i}vh !important;
}

.ncc-min-height-#{$i} {
    min-height: #{$i}#{"%"};
}

.ncc-min-height-px-#{$i} {
    min-height: #{$i}px;
}

.ncc-max-height-px-#{$i} {
    max-height: #{$i}px;
}

.ncc-w-#{$i} {
    width: #{$i}#{'%'} !important;
}

.ncc-min-w-#{$i} {
    min-width: #{$i}#{'%'};
    ;
}

.ncc-mw-#{$i} {
    max-width: #{$i}#{'%'};
    ;
}

// Here
.ncc-mr-#{$i} {
    margin: #{$i}#{'%'};
}

.ncc-mrt-#{$i} {
    margin-top: #{$i}#{'%'};
}

.ncc-mrr-#{$i} {
    margin-right: #{$i}#{'%'};
}

.ncc-mrb-#{$i} {
    margin-bottom: #{$i}#{'%'};
}

.ncc-mrl-#{$i} {
    margin-left: #{$i}#{'%'};
}

.ncc-mr-px-#{$i} {
    margin: #{$i}px;
}

.ncc-mrt-px-#{$i} {
    margin-top: #{$i}px;
}

.ncc-mrr-px-#{$i} {
    margin-right: #{$i}px;
}

.ncc-mrb-px-#{$i} {
    margin-bottom: #{$i}px;
}

.ncc-mrl-px-#{$i} {
    margin-left: #{$i}px;
}

.border-rad-#{$i} {
    border-radius: #{$i}#{'%'};
}

.border-rad-px-#{$i} {
    border-radius: #{$i}px;
}

.ncc-fl-#{$i} {
    flex: 1 1 #{$i}#{'%'};
}

}

.ncc-pos-relative {
    position: relative;
}

.main-input-style {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color .3s ease-in-out, box-shadow .3s ease-in-out;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        border-radius: 10px;
    }

    &.formvalidate-invalid {
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(255, 123, 123, 0.25);
            border-radius: 10px;
        }
    }

}

.search-svg {
    position: absolute;
    bottom: 12px;
    left: 10px;
}

@each $type in $over-flows {
    .overflow-#{$type} {
        overflow: #{$type}
    }
}

.ncc-btn {
    &-green {
        background-color: #227768;
        padding: 13px 20px;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #227768;

        &:active {
            outline: none
        }

        &:active {
            outline: none;
            // transform: translateY(-5px);
            // background-color: #fff;
            // color: #227768;
            // border: 1px solid #227768;
        }

        &:hover {
            background-color: #fff;
            color: #227768;
            border: 1px solid #227768;
        }
    }

    &-blue {
        background-color: #2f88d9;
        padding: 13px 20px;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:active {
            outline: none;
            // transform: translateY(-5px);
            // background-color: #fff;
            // color: #2f88d9;
            // border: 1px solid #2f88d9;
        }

        &:hover {
            background-color: #fff;
            color: #2f88d9;
            border: 1px solid #2f88d9;
        }
    }

    &-transparent {
        background-color: transparent;
        border: 1px solid #495057;
        color: #1f1e1e;
        padding: 4px 8px;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 8px;
        ;

        &:active {
            outline: none
        }

        &:active {
            outline: none;
            transform: translateY(-5px);
            background-color: #fff;
            color: #2f88d9;
            border: 1px solid #2f88d9;
        }

        &:hover {
            background-color: #2f88d9;
            color: #fff;
        }
    }

    &-big {
        padding: 15px 24px !important;
    }

    &-animated {
        &:hover {
            transform: translateY(-5px);
            outline: none;
            border: 1px solid transparent !important;
        }

        &:active {
            transform: translateY(0px);
        }
    }
}

@each $ta in $text-aligns {
    .text-align-#{$ta} {
        text-align: #{$ta};
    }
}

.ncc-org-table {
    border: 2px solid #dee2e6;

    thead {
        tr {
            border-bottom: 2px solid #dee2e6;

            th {
                border-right: 2px solid #dee2e6;
                border-bottom: 2px solid #dee2e6;
            }
        }
    }

    tbody {
        tr {
            td {
                border-right: 2px solid #dee2e6;
                border-bottom: 2px solid #dee2e6;
            }

        }
    }

    .border-bottom-0 {
        td {
            border-bottom: 0;
        }
    }
}

.al-ctr {
    align-items: center;
}

.border-bottom-0 {
    border-bottom: none;
}

.log-out {
    &-active {
        text-decoration: underline;
        color: red;
    }
}

.square {
    background-color: red;
}

.ncc-custom-file-input {
    input[type="file"] {
        display: none;
    }
}

.feedback {
    &-valid {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #28a745;
    }

    &-invalid {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
        transition: all 0.3s ease;
    }
}

.formvalidate {
    &-invalid {
        border-color: #dc3545 !important;
        transition: all 0.3s ease;
    }

    &-valid {}
}

a.item {
    text-decoration: none;
}
.text-red {
    color: #ff0000;
}

.formvalidate-invalid__control {
    border-color: #dc3545 !important;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(255, 123, 123, 0.25);
        border-radius: 10px;
    }
}

.file-attached {
    color: #58988a;
    // display: inline-block;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.required-mark {
    color: red;
}

.item {
    &.pending-review {
        color: orange;
    }
    &.view {

    }
}

.delete-user {
    color: red;
    margin: 0 10px;
    &:hover {
        color: red;
    }
}

.tox-tinymce {}

.ncc-select-custom__menu,
.ncc-select-custom__menu {
    z-index: 3 !important;
}

.custom-tmce-formvalidate-invalid {
    .tox-tinymce {
        border-color: #dc3545;
        transition: all 0.3s ease;

        &:focus {
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(255, 123, 123, 0.25);
            border-radius: 10px;
        }
    }
}

.otp-button {
    &-filled {
        background-color: 
        #398eb0 !important;
        border-radius: 10px !important;
        padding: 10px 20px !important;
        margin-bottom: 8px !important;
        color: #fff !important;
        font-size: 16px !important;
        outline: none !important;
        border: 1px solid #398eb0 !important;
        &:hover {
            outline: none !important;
            background-color: transparent !important;
            color: #398eb0 !important;
        }
        &:active {
            outline: none !important;
            border: 1px solid #398eb0 !important;
        }
    }
    &-transparent {
        background-color: transparent !important;
        border-radius: 10px !important;
        padding: 10px 20px !important;
        margin-bottom: 8px !important;
        color: #1f1e1e !important;
        font-size: 16px !important;
        outline: none !important;
        border: 1px solid #398eb0 !important;
        &:hover {
            outline: none !important;
            background-color: #398eb0 !important;
            color: #fff !important;
        }

    }
}

.otp-count-down {
    background-color: #f2f2f6;
    border-radius: 10px;
    padding: 10px 20px;
}

.ncc-otp-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-flow: column wrap;
    input {
        width: 50px !important;
        height: 50px;
        padding: 0;
        margin-right: 5px;
        margin-left: 5px;
        text-align: center;
        border: 1px solid gray;
        border-radius: 5px;
        &:last-child {
            margin-right: 0;
        }
        &::-webkit-inner-spin-button, 
        &::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
        }
        &:focus,
        &.focus {
            border-color: #398eaf ;
            outline: none;
            box-shadow: none;
        }
    }
}

.border {
    &-bottom {
        border-bottom: 1px solid #1f1e1e;
    }
}

.section {
    padding-top: 15px;
    margin-bottom: 35px;
}

.react-datepicker-popper {
    z-index: 3 !important;
}

.unset-padding{
    padding: 0 !important;
}
input[name="start_date_picker"], input[name="end_date_picker"]{
    width: 100%;
    text-indent: 6px;
}
.full-height{
    height: 100% !important;
}

.react-datepicker__input-container{
    height: inherit;
}

.nc-date-picker {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

.remark-table {
    width: 100%;
    th, tr, td {
        border: 1px solid #e7e7e7;
        padding: 10px;
    }
    .remark-table__date {
        padding-right: 10px;
    }
    .remark-data-content {
        display: inline-block;
    }
}

.button {
    &-action {
        min-width: 94px !important;
        font-size: 16px !important;
        padding-top: 10px;
        padding-top: 10rem;

    }
    &-extend, &-discharge, &-endorse, &-approve {
        background-color: #0dcaf0 !important;
        color: #fff !important;
        &:hover {
            background-color: #fff !important;
            color: #0dcaf0 !important;
            border: 1px solid #0dcaf0 !important;
        }
    }
    &-cancel {
        font-size: 16px !important;
        outline: none !important;
        border: 1px solid #398eb0 !important;
        &:hover {
            outline: none !important;
            background-color: #398eb0 !important;
            color: #fff !important;
        }
    }
    &-reject {
        background-color: #ffc107 !important;
        color: #fff !important;
        &:hover {
            background-color: #fff !important;
            color: #ffc107 !important;
            border: 1px solid #ffc107 !important;
        }
    }
    &-save {
        background-color: #198754 !important;
        color: #fff !important;
        &:hover {
            background-color: #fff !important;
            color: #198754 !important;
            border: 1px solid #198754 !important;
        }
    }
    &-not-allowed {
        background-color: #6c757d !important;
        color: #fff !important;
    }
    &-delete {
        background-color: #e41212 !important;
        color: #fff !important;
        &:hover {
            background-color: #fff !important;
            color: #e41212 !important;
            border: 1px solid #e41212 !important;
        }
    }
}

// Test form radio
.custom-form {
    .form-check {
        padding-left: 0;
        &-inline {
            margin-right: 30px;
        }
    }
    &:has(.form-check-input.checkbox-style) {
        padding-left: 35px;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    // display: flex;
    // justify-content: space-around;
}

// input[type='radio'].checkbox-style {
//     display: none;
// }

input[type='radio'].checkbox-style {
    // position: relative;
    // display: flex;
    // align-items: center;
    // padding-left: 30px;
    // &::before {
    //     content: '';
    //     width: 20px;
    //     height: 20px;
    //     border-radius: 4px;
    //     border: 1px solid #bbb;
    //     display: block;
    //     margin-right: 8px;
    //     position: absolute;
    //     left: 0px;
    //     top: 50%;
    //     transform: translate(0, -50%);
    // }
    // padding: 10px;
    // display: block;
    // background: #ddd;
    // border: 1px solid #bbb;
    // border-radius: 3px;
}

// input[type='radio'].checkbox-style:checked + label {
//     &::before {
//         background-color: #2f88d9;
//         border: 1px solid #2f88d9;
//     }
//     &::after {
//         content: '';
//         position: absolute;
//         width: 14px;
//         height: 8px;
//         border-width: 0 0 2px 2px;
//         border-color: #fff;
//         border-style: solid;
//         left: 1.5px;
//         top: 50%;
//         transform: rotate(-45deg) translate(40%, -40%);
//     }
// }

@each $i in $text-color-styles {
    .text-color-#{$i} {
        color: #{$i} !important;
    }
}

  
@each $i in $cursor-styles {
    .cursor-#{$i} {
        cursor: #{$i} !important;
        pointer-events: unset !important;
    }
}

@media (max-width: 1024px) {
    .ncc-case-search-wrapper {
        flex-flow: row wrap;
        .search-input {
            flex: 1 1 100% !important;
            max-width: 100% !important;
            margin-bottom: 8px;
            padding-left: 0;
            padding-right: 0;
        }
        .other-search-input {
            flex: 1 1 25% !important;
            max-width: 25% !important;
            padding-left: 0;
            &.first-other {
                padding-left: 0;
                &.other-date-picker {
                }
            }
            &.last-other {
                padding-right: 0;
                display: flex;
                width: 100%;
                a {
                    flex: 1 1 50% !important;
                    max-width: 50% !important;
                }
            }
            > div .other-date-picker-inner {
                flex: 1 1 20% !important;
                max-width: 20% !important;
                justify-content: flex-start !important;
            }

            > div .other-date-picker-datepicker {
                flex: 1 1 80% !important;
                max-width: 80% !important;
                justify-content: flex-end !important;
            }
            
        }
    }
    .margin {
        &-left-0 {
            margin-left: 0 !important;
        }
        &-right-0 {
            margin-right: 0 !important;
        }
    }
}

@media (max-width: 834px) {
    .ncc-case-search-wrapper {
        flex-flow: row wrap;
        padding-left: 12px;
        padding-right: 12px;
        justify-content: space-between;
        .search-input {
            flex: 1 1 100% !important;
            max-width: 100% !important;
            margin-bottom: 8px;
            padding-left: 0;
            padding-right: 0;
        }
        .other-search-input {
            flex: 1 1 33% !important;
            max-width: 33% !important;
            margin-bottom: 8px;
            padding-left: 0;
            &.picker-ministry {
                padding-right: 0;
            }
            &.first-other {
                padding-left: 0;
                &.other-date-picker {
                }
            }
            &.last-other {
                flex: 1 1 100% !important;
                max-width: 100% !important;
                width: 100%;
                display: flex;
                a {
                    flex: none !important;
                    max-width: unset !important;
                }
            }
            > div .other-date-picker-inner {
                flex: 1 1 20% !important;
                max-width: 20% !important;
                justify-content: flex-start !important;
            }

            > div .other-date-picker-datepicker {
                flex: 1 1 80% !important;
                max-width: 80% !important;
                justify-content: flex-end !important;
            }
            
        }
    }
    .margin {
        &-left-0 {
            margin-left: 0 !important;
        }
        &-right-0 {
            margin-right: 0 !important;
        }
    }
}

@media (min-width: 1025px) and (max-width: 1398px) {
    .ncc-case-search-wrapper {
        .search-input {
        }
        .other-search-input {
            &.first-other {
                &.other-date-picker {
                }
            }
            &.last-other {
                flex: 1 1 100% !important;
                max-width: 100% !important;
                width: 100%;
                display: flex;
                a {
                    white-space: nowrap;
                }
            }
            > div .other-date-picker-inner {

            }

            > div .other-date-picker-datepicker {
            }
            
        }
    }
}